import React from "react";

export default function SpCaution() {
  return (
    <div style={{marginRight: 25,marginLeft: 25}}>
      <div
        style={{
          width: "auto",
          textAlign: "center",
          fontSize: 22,
        }}
      >
        ※ ※ ※ C A U T I O N ※ ※ ※
      </div>
      <br />
      当サイトはいちから株式会社にじさんじ所属バーチャルライバーの戌亥とこ様の非公式ファンサイトとなります。
      公式とは一切の関係が無く、1人のリスナーが戌亥とこ様の誕生日企画のためだけに立ち上げたサイトであります。
      <br />
      <br />
      いちから株式会社様の二次創作ガイドラインを熟読し、二次創作という形で発表をさせていただいておりますが、
      もし万が一こちらの規約に抵触すると判断が下された場合は即刻公開を取りやめるものとします。
      <br />
      <br />
      また、大変恐縮ではございますが、いちから株式会社様ならびに戌亥とこ様が規約に抵触すると判断された場合は下記メールアドレスへのご連絡願います。
      <br />
      <br />
      inuitokounofficialfansite@gmail.com
      <br />
      <br />
      以上
    </div>
  );
}
